import React from "react";
// Customizable Area Start
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, InputAdornment, Modal, TextField, Tooltip, Typography, Snackbar } from "@material-ui/core";
import { Formik, Field, FormikProps } from 'formik';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import Select from 'react-select'
import { accordition_icon, closeIcon, addPlus, addPlusColor, registerDeal, registerReferral, calendarIcon, leftArrowIcon, rightArrowIcon, closeWhiteIcon, starIcon, starFillIcon } from "./assets";
import { leadCompanyregistrationSchema } from "../../../components/src/validations";
import { getWrapperClassName } from "../../../components/src/commonUsage";
import RegisterReferralSkeleton from "./RegisterReferralSkeleton";
import { trackEvent } from "../../../components/src/analytics";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
const configJSON = require("./config");
// Customizable Area End
import LeadManagementController, {
  Props
} from "./LeadManagementController";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import './css/styles.css'
import moment from "moment";
import { Alert, Rating } from "@material-ui/lab";
import { dropDownIcon, searchIcon } from "../../user-profile-basic/src/assets";

export default class RegisterReferral extends LeadManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    this.fileInputNewRef = React.createRef();
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getCompanies()
    this.getCountries()
    this.fetchAllField()
    this.loadDataFromLocal()

    trackEvent('register_referral/deal_cta_clicked', 'User clicks on the Register/rererral CTA', {dewey_code:"B3.2",flow:2});

    addEventListener("beforeunload", (event) => {
      this.persistFormData()
      return "leaved the page"
    });
  }


  async componentWillUnmount() {
    this.persistFormData()
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  FormHeader = ({ formik }: { formik: any }) => {
    return (
      <Box className="referral-heading-wrapper">
        <Box className="deal-heading-left">
          <Typography className="deal-form-heading">
            <label className="deal-form-heading-icon">
              <img src={registerDeal} />
            </label>Share details to register
          </Typography>
          
          {
            this.state.draftLoading && !this.state.refferalStatus.isSave ?
              <Box className="save-draft-loader">
                <span>Saving draft...</span>
                <span className="loader"></span>
              </Box> :
              <Tooltip title={this.state.draftLeads.deleteDraftId ? "All changes have been saved as draft"
                : "Your draft will be saved automatically once an email is entered."}
                placement="bottom" arrow>
                <Typography className="underline-link">
                  {`${this.state.draftLeads.deleteDraftId ? "Draft saved" : "Add email to save draft"}`}
                </Typography>
              </Tooltip>
          }
        </Box>

        <Box className="deal-heading-right">
          <div className="view-draft-wrapper">
            <Button
              data-test-id="button"
              onClick={() => {
                if(this.state.refferalStatus.disableAllField){
                  this.navigateToViewDraft()
                  return
                }
                this.setState({navigateToDraft:{isClicked:true,navigate:false}})
              }}
              className="custom-btn btn-md text-sm gray-btn"
              style={{ textTransform: 'none' }}
            >
              View drafts
            </Button>
          </div>
          <Box className="btn-wrapper">
            {this.state.draftLeads.deleteDraftId&&<Button
              data-test-id="button"
              onClick={() => {
                if (this.state.draftLeads.deleteDraftId)
                  this.openDiscardModal(this.state.draftLeads.deleteDraftId)
              }}
              className="custom-btn btn-md text-sm gray-btn"
              style={{ textTransform: 'none' }}
              disabled={this.state.refferalStatus.disableAllField}
            >
              Discard
            </Button>}
            <Button data-test-id="button"
              type="submit"
              className="custom-btn btn-md text-sm green-btn"
              style={{ textTransform: 'none' }}
              disabled={this.state.refferalStatus.disableAllField}
            >
              Register referral
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }

  Label = ({ label, isRequired = true, error }: { label: string, isRequired?: boolean, error: boolean }) => {
    return (
      <label className="select-label">
        {label}
        {isRequired ? <span style={{ color: error ? "red" : "gray" }}>*</span> : <></>}
      </label>
    )
  }
  
  CompanyField = ({ formik }: { formik: FormikProps<any> }) => {
    const { TextFieldLabel } = this
    return (
      <Box>
        <TextField
          name="company_name.name"
          size="small"
          fullWidth
          InputLabelProps={{shrink:this.state.companyNameFocus || formik.values.company_name.name?.length?true:false}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ExpandMoreIcon />
              </InputAdornment>
            ),
            
          }}
          onChange={(event) => {
            this.setState({
              companyArray: [],
              companyFilter: {
                ...this.state.companyFilter,
                page: 1,
                search: event.target.value,
                isLoading: true
              }
            })
            formik.setFieldValue('company_name', { name: event.target.value, id: "" })
          }}
          value={formik.values.company_name?.name}
          label={<TextFieldLabel label="Company name" isRequired={true} error={this.getFormikError(formik.touched.company_name, formik.errors.company_name)} />}
          error={this.getFormikError(formik.touched.company_name, formik.errors.company_name)}
          id="input-with-icon-textfield"
          variant="filled"
          onFocus={() => { this.setState({ companyOptionModal: true,companyNameFocus:true }) }}
          onBlur={() => {
            setTimeout(() => {
              if(formik.values.company_name.name){
                this.checkCompanyExist(formik.values.company_name.name)
              }
              this.setState({ companyOptionModal: false,companyNameFocus:false })
            }, 500)
          }}
          disabled={this.state.refferalStatus.disableAllField}
        />
        {
          this.state.companyOptionModal &&
          <div
            data-test-id="scroll"
            ref={this.companyRef}
            onScroll={this.onScroll}
            style={{ maxHeight: "220px", marginTop: '5px', overflow: "scroll", borderRadius: "8px", boxShadow: "0px 2px 4px 0px #00000026" }}>
            <Box className="addPlusIcon"
              onClick={() => {
                let data = {
                  id: "", name: formik.values.company_name.name
                }

                if (!this.state.disableSameKey && !formik.values.company_name.id && this.state.companyFilter.search.length) {
                  formik.setFieldValue('company_name', data)
                  this.createNewCompany()
                  this.setState({ companyOptionModal: false })
                }
              }}
              display={"flex"}
              alignItems={'center'} padding={"12px"}
              style={{
                color: "#3C3E49",
                background: this.state.companyArray.length || this.state.companyFilter.isLoading ? '#F8F9FA' : '#F3EBFF'
              }}
            >
              {this.state.companyArray.length || this.state.companyFilter.isLoading ? <> <img height={20} width={20} style={{ marginRight: '8px' }} src={addPlus} />  Create new </> : <> <img src={addPlusColor} height={18} width={18} style={{ marginRight: '8px' }} />  <span style={{ color: '#6200EA', marginRight: '3px' }}>Create new: </span> {formik.values.company_name.name} </>}
            </Box>
            {
              this.state.companyFilter.isLoading ?
                <Box style={{ color: "#9B9FB1", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "12px 16px" }}>
                  <Typography style={{ fontSize: "16px" }}>
                    Searching...
                  </Typography>
                  <span className="loader"></span>
                </Box>
                : this.state.companyArray.map((item: any, index: number) => (
                  <Box className="addPlusIcon"
                    key={index}
                    style={{ color: "#3C3E49" }}
                    padding={"12px 16px"}
                    onClick={() => {
                      formik.setFieldValue('company_name', { ...item, name: item.company_name });
                      this.setState({ companyOptionModal: false, createNewCompany: false })
                    }}>
                    {item.company_name}
                  </Box>
                ))
            }
          </div>
        }

      </Box>
    )
  }

  DropDown = (props: { label: string, formik: FormikProps<any>, name: string, onBlur: any, onFocus: any, focused: boolean, options: { label: string, value: string }[], isRequired?: boolean }) => {
    const { Label } = this
    const error = this.getFormikError(props.formik.touched?.[props.name], props.formik.errors?.[props.name]) || false

    return (
      <Box className={`${getWrapperClassName(
        "custom-search-dropdown-wrapper",
        props.focused ? "is-focused check-focus" : "is-focused", props.formik.values[props.name]?.value,
        props.focused)} ${error ? "custom-search-select-error" : ""}`}
      >
        <Label data-test-id="label" label={props.label} error={error} isRequired={props.isRequired || false} />
        <Select
          placeholder=" "
          className="custom-search-select"
          name={props.name}
          styles={selectStyle}
          options={props.options}
          value={props.formik.values[props.name]}
          onChange={(option: any) => {
            props.formik.setFieldValue(props.name, option)
          }}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          components={{
            IndicatorSeparator: () => null,
          }}
          isDisabled={this.state.refferalStatus.disableAllField}
        />
        {/* <ErrorMessage className="deal-error-text" name="product_type" component="div" /> */}
      </Box>
    )
  }

  TextFieldLabel = ({ label, error, isRequired }: { label: string, error?: boolean, isRequired?: boolean }) => {
    return (
      <label>{label}
        {isRequired && <span style={{ color: error ? "red" : "gray" }}>*</span>}</label>
    )
  }

  RequiredFieldAlert = ({ formik }: { formik: FormikProps<any> }) => {
    return (
      Object.keys(formik.errors).length && formik.submitCount && !this.state.refferalStatus.isSave? 
      (Object.keys(formik.errors).every(x=>x)?
        <Alert className="draft-error" severity="error" icon={false}>
          There are a few mandatory fields that aren’t filled yet. Kindly fill them all before registering referral
        </Alert>:<></>) :<></>
    )
  }

  DraftAlert=()=>{
    return(
      this.state.validateEmail.isDraft ?
      <Alert className="draft-error" severity="error" icon={false}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {this.state.validateEmail.message}
          <p
            onClick={this.editDraft}
            style={{ textDecoration: "underline", paddingLeft: "5px", cursor: "pointer", margin: "0" }}>
            edit existing draft
          </p>
        </div>
      </Alert>:<></>
    )
  }

  ErrorMesage=({formik,name}:{formik:FormikProps<any>,name:string})=>{
    return(
      formik.values[name]?.length?
      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense">{formik.errors[name]}</p>:<></>)
  }

  PhoneDropDown=(formik:FormikProps<any>)=>{
    return(this.state.countryDropDown&&
    <Box
      data-test-id="dropDown"
      style={{
        left:"0",
        right:"0",
        background: "#FFF",
        zIndex: 999,
        boxShadow: "0px 4px 16px 0px #00000026",
        borderRadius: "8px",
        top:"60px",
        position:"absolute"
      }}
      id="phone-drop"
      onBlur={()=>{
        setTimeout(()=>{
          this.setState({countryDropDown:false,search:""})
        },500)
      }}
      >
      <Box>
        <TextField
          type="text"
          placeholder="Search"
          className="pic-search-input"
          fullWidth
          value={this.state.search}
          onChange={event=>{
            this.setState({search:event.target.value})
          }}
          autoFocus
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="email"
                  edge="end"
                >
                  <img src={searchIcon} />
                </IconButton>
              </InputAdornment>
          }} />
          <Box style={{overflowY:"scroll",height: "200px"}}>
        {
          this.filterCountryData().map((country: any) => (
            <Box
              display={"flex"}
              alignItems={"center"}
              className="country-code"
              onClick={() => { 
                formik.setFieldValue('lead_country_code',{
                  value: `+${country.value}`,
                  label: `+${country.value}`,
                  countryLabel: `+${country.value}`,
                })
                this.setState({countryDropDown:false})
              }}
            >
              <span className={`fi fi-${country.code}`}></span>
              <Typography
                style={{ fontSize: "14px", color: "#8b8a9e", lineHeight: "24px", fontWeight: 400 }}>
                {`${country.name} +${country.value}`}
              </Typography>
            </Box>
          ))
        }
        </Box>
      </Box>
    </Box>)
  }

  renderReferralForm = (formik: FormikProps<any>) => {
    const { FormHeader, TextFieldLabel, DropDown, RequiredFieldAlert, CompanyField, DraftAlert, ErrorMesage } = this
    return (<form className="deal-form" onSubmit={formik.handleSubmit}>
      <Box className="deal-form">
        <Box className="referral-form-wrapper">
          {/* HEADER */}
          <FormHeader data-test-id="formheader" formik={formik} />

          <Box className="referral-form-content" style={{height: "40vh",minHeight: "100%",overflow: "scroll",paddingBottom:"14rem"}}>
            <DraftAlert data-test-id="DraftAlert"/>

            <RequiredFieldAlert data-test-id="RequiredFieldAlert" formik={formik} />
            {/* Basic info */}
            <Box className="deal-form-container">
              <Accordion data-test-id="Accordion" expanded={this.state.accordionStatus.basicInformation}
                onChange={(_, isOpen) =>
                  this.updateAccordionStatus('basicInformation', isOpen)
                } className="deal-form-accordition">
                <AccordionSummary
                  expandIcon={<div className="accordition-icon"><img src={accordition_icon} /></div>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="deal-form-heading">Basic information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="deal-form-internal-container">
                    <Box>
                      <Grid className="grid-even-space">
                        <Grid item xs={12} md={12}>
                          <TextField
                            name="first_name"
                            size="small"
                            fullWidth
                            id="input-with-icon-textfield"
                            className={this.phoneNumberClass(formik.values.first_name,formik.errors.first_name,"custom-search-select-error")}
                            variant="filled"
                            label={<TextFieldLabel label="First name" isRequired={true} error={this.getFormikError(formik.touched.first_name, formik.errors.first_name)} />}
                            value={formik.values.first_name}
                            onChange={(event)=>{
                              this.setFieldValueWithOutSpace('first_name',event.target.value,formik)
                            }}
                            disabled={this.state.refferalStatus.disableAllField}
                            onBlur={formik.handleBlur}
                            error={this.getFormikError(formik.touched.first_name, formik.errors.first_name)}
                          />
                          <ErrorMesage formik={formik} name="first_name"/>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            name="last_name"
                            size="small"
                            fullWidth
                            variant="filled"
                            value={formik.values.last_name}
                            className={this.phoneNumberClass(formik.values.last_name,formik.errors.last_name,"custom-search-select-error")}
                            onChange={(event)=>{
                              this.setFieldValueWithOutSpace('last_name',event.target.value,formik)
                            }}
                            id="input-with-icon-textfield"
                            disabled={this.state.refferalStatus.disableAllField}
                            label={<TextFieldLabel label="Last name" isRequired={true} error={this.getFormikError(formik.touched.last_name, formik.errors.last_name)} />}
                            error={this.getFormikError(formik.touched.last_name, formik.errors.last_name)}
                            onBlur={formik.handleBlur}
                          />
                          <ErrorMesage formik={formik} name="last_name"/>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="mt-1">
                      <Grid className="grid-even-space">
                        <Grid item xs={12} md={12}>
                          <TextField
                            size="small"
                            variant="filled"
                            className={this.phoneNumberClass(formik.values.customer_email,formik.errors.customer_email,"custom-search-select-error")}
                            data-test-id="customer-email"
                            label={<TextFieldLabel label="Email" isRequired={true} error={this.getFormikError(formik.touched.customer_email, formik.errors.customer_email)} />}
                            fullWidth
                            value={formik.values.customer_email}
                            onChange={(e) => {
                              localStorage.removeItem("fromDraft")
                              formik.handleChange(e);
                              setTimeout(() => {
                                if (!formik.errors.customer_email) {
                                  this.setState({
                                    validateEmail: { ...this.state.validateEmail, email: e.target.value }
                                  })
                                }
                              }, 200)
                            }}
                            name='customer_email'
                            // type="email"
                            error={this.emailValidateError(formik)}
                            onBlur={()=>{
                              if (!formik.errors.customer_email) {
                                this.setState({
                                  validateEmail: { ...this.state.validateEmail, email: formik.values.customer_email }
                                })
                                this.checkLeadEmail(formik.values.customer_email)
                              }
                            }}
                            helperText={!this.state.validateEmail.isDraft && this.state.validateEmail.message}
                          />
                          <ErrorMesage data-test-id="EmailError" formik={formik} name="customer_email"/>
                        </Grid>


                        <Grid item xs={12} md={12} style={{ position: "relative" }}>
                          <Box display={"flex"} alignItems={"center"}
                            sx={{ border: `solid 1px ${this.phoneBorderColor(formik)}`, borderRadius: "8px" }}
                            className={`
                          ${getWrapperClassName("phoneNumber",
                              this.state.leadCountryCodeFocus ? "is-focused check-focus" : "is-focused",
                              formik.values.lead_country_code,
                              this.state.leadCountryCodeFocus)}
                          ${this.phoneNumberClass(formik.values.phone_number, formik.errors.phone_number, "custom-search-select-error")}
                          `}
                          >
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              data-test-id="dropDown"
                              className={`currency-dropdown country-dropdown ${this.getFormikError(formik.touched.phone_number, formik.errors.phone_number) ? "custom-search-select-error" : ""}`}
                              style={{ padding: "16px", borderRight: "solid 1px #C0C3CE", gap: "8px" }}
                              onClick={() => { this.setState({ countryDropDown: !this.state.countryDropDown,search:"" }) }}
                              onFocus={() => this.handleFocus('lead_country_code')}
                              onBlur={() => this.handleBlur('lead_country_code')}
                            >
                              <span className={`fi fi-${this.getCountryFlag(formik.values.lead_country_code.countryLabel)}`}></span>
                              <Typography style={{ color: "#3C3E49" }}>{formik.values.lead_country_code.label}</Typography>
                              <img src={dropDownIcon} />
                            </Box>
                            <TextField
                              name="phone_number"
                              size="small"
                              variant="filled"
                              data-test-id="txtInputCurrentPassword"
                              type={"text"}
                              label={<TextFieldLabel label="Phone number" isRequired={true} error={this.getFormikError(formik.touched.phone_number, formik.errors.phone_number)} />}
                              value={formik.values.phone_number}
                              onChange={(e) => {
                                formik.handleChange(e)
                              }}
                              
                              className="phone-readonly-input"
                              style={{ flex: 1 }}
                              onFocus={() => this.handleFocus('lead_country_code')}
                              onBlur={() => this.handleBlur('lead_country_code')}
                              disabled={this.state.refferalStatus.disableAllField}
                            />
                          </Box>
                          <ErrorMesage formik={formik} name="phone_number" data-test-id="PhoneNumberError" />
                          {this.PhoneDropDown(formik)}
                        </Grid>

                        {/* <Grid item xs={12} md={12}>
                          <div className={`
                          ${getWrapperClassName("input-with-adornment country-dropdown-wrapper", 
                          this.state.leadCountryCodeFocus ? "is-focused check-focus" : "is-focused", 
                          formik.values.lead_country_code, 
                          this.state.leadCountryCodeFocus)}
                          ${this.phoneNumberClass(formik.values.phone_number,formik.errors.phone_number,"custom-search-select-error")}
                          `}
                          >
                            <Field name="lead_country_code">
                              {(field: any) => (
                                <Select
                                  data-test-id="dealCountryCode"
                                  {...field}
                                  name="lead_country_code"
                                  styles={countryStyles}
                                  className={`currency-dropdown country-dropdown ${this.getFormikError(formik.touched.phone_number, formik.errors.phone_number) ? "custom-search-select-error" : ""}`}
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  options={this.state.countryCodes.map((item: any) => {
                                    return {
                                      value: `+${item.value}`,
                                      label: <><span className={`fi fi-${item.code.toLowerCase()}`}></span><label className="country-code-color">{`${item.name} +${item.value}`}</label> </>,
                                      // countryValue: `+${item.value}`, 
                                      countryLabel: <><span className={`fi fi-${item.code.toLowerCase()}`}></span><label className="country-code-color">{`+${item.value}`}</label> </>
                                    }
                                  })}
                                  onChange={(option) => {
                                    formik.setFieldValue('lead_country_code', option)
                                  }}
                                  value={{ label: formik.values.lead_country_code.countryLabel }}
                                  // onBlur={formik.handleBlur}
                                  onFocus={() => this.handleFocus('lead_country_code')}
                                  onBlur={() => this.handleBlur('lead_country_code')}
                                  isDisabled={this.state.refferalStatus.disableAllField}
                                />
                              )}
                            </Field>
                            <TextField
                              data-test-id="phone_number"
                              name="phone_number"
                              size="small"
                              className="phone-no-field"
                              label={<TextFieldLabel label="Phone number" isRequired={true} error={this.getFormikError(formik.touched.phone_number, formik.errors.phone_number)} />}
                              variant="filled"
                              fullWidth
                              value={formik.values.phone_number}
                              onChange={formik.handleChange}
                              onFocus={() => this.handleFocus('lead_country_code')}
                              onBlur={() => this.handleBlur('lead_country_code')}
                              disabled={this.state.refferalStatus.disableAllField}
                              error={this.getFormikError(formik.touched.phone_number, formik.errors.phone_number)}
                            />
                          </div>
                          <ErrorMesage formik={formik} name="phone_number" data-test-id="PhoneNumberError"/>
                        </Grid> */}
                      </Grid>
                    </Box>
                    <Box className="mt-1">
                      <Grid className="grid-even-space">
                        <Grid
                          item
                          xs={12}
                          md={12}>
                          <TextField
                            size="small"
                            variant="filled"
                            label={<TextFieldLabel label="Position" isRequired={true} error={this.getFormikError(formik.touched.designation, formik.errors.designation)} />}
                            fullWidth
                            value={formik.values.designation}
                            onChange={
                              formik.handleChange
                            }
                            inputProps={{maxLength:50}}
                            name='designation'
                            type="text"
                            error={this.getFormikError(formik.touched.designation, formik.errors.designation)}
                            disabled={this.state.refferalStatus.disableAllField}
                          // helperText={this.getFormikHelperText(formik.touched.designation, formik.errors.designation)}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* Company info */}
            <Box className="deal-form-container">
              <Accordion data-test-id="Accordion" expanded={this.state.accordionStatus.companyInformation}
                onChange={(_, isOpen) =>
                  this.updateAccordionStatus('companyInformation', isOpen)
                } className="deal-form-accordition">
                <AccordionSummary
                  expandIcon={<div className="accordition-icon"><img src={accordition_icon} /></div>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="deal-form-heading">Company information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="deal-form-internal-container">
                    <Box>
                      <Grid className="grid-even-space">
                        <Grid item xs={12} md={12}>
                          <CompanyField data-test-id="CompanyField" formik={formik} />
                          {/* <ErrorMessage className="deal-error-text" name="company_name.name" component="div" /> */}
                          {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <DropDown
                            data-test-id="dropdown"
                            label="Country"
                            isRequired={true}
                            focused={this.state.createNewCompany ? this.state.countryFocus : this.state.leadCountry}
                            formik={formik}
                            name={this.state.createNewCompany ? "country" : "lead_country"}
                            options={this.state.countryData}
                            onFocus={this.dropDownOnFocus}
                            onBlur={this.dropDownInBlur}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    {this.state.createNewCompany &&
                      <>
                        <Box className="mt-1">
                          <Grid className="grid-even-space">
                            <Grid item xs={12} md={12}>
                              <DropDown
                                label="Industry"
                                isRequired={true}
                                focused={this.state.industryFocus}
                                formik={formik}
                                name={"industry"}
                                options={this.dropDownValuesMapper(this.state.allFieldValues.qualification_listing.industry_listing)}
                                onFocus={() => this.handleFocus("industry")}
                                onBlur={() => this.handleBlur("industry")}
                              />
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <TextField
                                size="small"
                                fullWidth
                                name='company_website'
                                variant="filled"
                                label="Company website"
                                value={formik.values.company_website}
                                onChange={
                                  formik.handleChange
                                }
                                error={this.getFormikError(formik.touched.company_website, formik.errors.company_website)}
                                disabled={this.state.refferalStatus.disableAllField}
                                helperText={this.getFormikHelperText(formik.touched.company_website, formik.errors.company_website)}
                                onBlur={formik.handleBlur}
                                />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box className="mt-1">
                          <Grid className="grid-even-space">
                            <Grid item xs={12} md={12}>
                              <DropDown
                                data-test-id="dropdown"
                                label="Number of employees"
                                focused={this.state.empFocus}
                                formik={formik}
                                name={"employees"}
                                options={this.dropDownValuesMapper(this.state.allFieldValues.qualification_listing.company_size_listing)}
                                onFocus={() => this.handleFocus("employees")}
                                onBlur={() => this.handleBlur("employees")}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    }
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* Qualification field */}
            <Box className="deal-form-container">
              <Accordion
                data-test-id="Accordion"
                expanded={this.state.accordionStatus.qualificationFields}
                onChange={(_, isOpen) =>
                  this.updateAccordionStatus('qualificationFields', isOpen)
                } className="deal-form-accordition">
                <AccordionSummary
                  expandIcon={<div className="accordition-icon"><img src={accordition_icon} /></div>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="deal-form-heading">Lead qualification</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="deal-form-internal-container">
                    <Box>
                      <Grid className="grid-even-space">
                        <Grid
                          item
                          xs={12}
                          md={12} >
                          <DropDown
                            data-test-id="dropdown"
                            label="Product type"
                            isRequired={true}
                            // focused={this.state.qualification.productType}
                            focused={this.state.productTypeFocus}
                            formik={formik}
                            name="product_type"
                            options={this.dropDownValuesMapper(this.state.allFieldValues.qualification_listing.product_listing)}
                            // onBlur={() => this.setState({ qualification: { ...this.state.qualification, productType: false } })}
                            // onFocus={() => this.setState({ qualification: { ...this.state.qualification, productType: true } })}
                            onFocus={() => this.handleFocus("productType")}
                            onBlur={() => this.handleBlur("productType")}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} >
                          <DropDown
                            data-test-id="dropdown"
                            label="Client persona description"
                            isRequired={true}
                            // focused={this.state.qualification.description}
                            focused={this.state.descriptionFocus}
                            formik={formik}
                            name="best_describe"
                            options={this.dropDownValuesMapper(this.state.allFieldValues.qualification_listing.best_describes_listing)}
                            // onBlur={() => this.setState({ qualification: { ...this.state.qualification, description: false } })}
                            // onFocus={() => this.setState({ qualification: { ...this.state.qualification, description: true } })}
                            onFocus={() => this.handleFocus("description")}
                            onBlur={() => this.handleBlur("description")}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="mt-1">
                      <Grid className="grid-even-space">
                        <Grid item xs={12} md={12}>
                          <DropDown
                            data-test-id="dropdown"
                            label="Client budget"
                            isRequired={true}
                            // focused={this.state.qualification.buget}
                            focused={this.state.bugetFocus}
                            formik={formik}
                            name="budget"
                            options={this.dropDownValuesMapper(this.state.allFieldValues.qualification_listing.budget_listing)}
                            // onBlur={() => this.setState({ qualification: { ...this.state.qualification, buget: false } })}
                            // onFocus={() => this.setState({ qualification: { ...this.state.qualification, buget: true } })}
                            onFocus={() => this.handleFocus("buget")}
                            onBlur={() => this.handleBlur("buget")}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}>
                          <div className="single-datepicker-container" ref={this.calendarRef}>
                            <TextField
                              id="date"
                              name="deal_name"
                              size="small"
                              variant="filled"
                              label="First contact date"
                              type="text"
                              onClick={() => {
                                this.setState({
                                  isCalendarOpen: !this.state.isCalendarOpen
                                })
                              }}
                              value={formik.values.contact_date}
                              fullWidth
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment 
                                    position="end"
                                    onClick={() => {
                                      if(this.state.isCalendarOpen){
                                        formik.setFieldValue("contact_date", "")
                                        this.closeCalander()}
                                      }}
                                  >
                                    <IconButton edge="end">
                                      <img src={this.state.isCalendarOpen ? closeIcon : calendarIcon} style={{ height: "14px" }} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              disabled={this.state.refferalStatus.disableAllField}
                            />
                            {console.log('this.state.isCalendarOpen: ', this.state.isCalendarOpen)}
                            {this.state.isCalendarOpen && (
                              <div className="calander-picker-wrap">
                                <div className="calander-picker">
                                  <div className="calander-inner" style={{ height: "270px" }}>
                                    <DatePicker
                                      isOpen={true}
                                      className="daterange-picker-css"
                                      onChange={(date:any)=>{
                                        // formik.setFieldValue("contact_date", moment(date).format("DD/MM/YYYY"))
                                        this.setState({
                                          calendarValue: date,
                                          // isCalendarOpen:false
                                        });

                                      }}
                                      value={this.state.calendarValue}
                                      clearIcon={null}
                                      inputRef={this.datePickerRef}
                                      shouldCloseCalendar={({ reason }) => false}
                                      prev2Label={null}
                                      next2Label={null}
                                      prevLabel={<img src={leftArrowIcon} />}
                                      nextLabel={<img src={rightArrowIcon} />}
                                    />
                                  </div>
                                  <div style={webStyles.rightAlign}>
                                    <Button data-test-id="close-calendar" onClick={() => {
                                      formik.setFieldValue("contact_date", "")
                                      this.setState({
                                        calendarValue: "",
                                        isCalendarOpen:false
                                      });
                                    }}>
                                      <Typography className="cal-cancel-btn">
                                        Cancel
                                      </Typography>
                                    </Button>
                                    <Button data-test-id="get-filter-data" onClick={() => {
                                      formik.setFieldValue("contact_date", moment(this.state.calendarValue).format("DD/MM/YYYY"))
                                      this.setState({
                                        isCalendarOpen:false
                                      });
                                    }}>
                                      <Typography className="cal-apply-btn">
                                        Apply
                                      </Typography>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="mt-1">
                      <Grid className="grid-even-space">
                        <Grid item xs={12} md={12}>
                          <DropDown
                            data-test-id="dropdown"
                            label="What platform would they like"
                            // focused={this.state.qualification.paltform}
                            focused={this.state.paltformFocus}
                            formik={formik}
                            name="platform_type"
                            options={this.dropDownValuesMapper(this.state.allFieldValues.qualification_listing.platform_listing)}
                            // onBlur={() => this.setState({ qualification: { ...this.state.qualification, paltform: false } })}
                            // onFocus={() => this.setState({ qualification: { ...this.state.qualification, paltform: true } })}
                            onFocus={() => this.handleFocus("paltform")}
                            onBlur={() => this.handleBlur("paltform")}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <DropDown
                            data-test-id="dropdown"
                            label="Timeline for project delivery"
                            isRequired={true}
                            // focused={this.state.qualification.dev}
                            focused={this.state.devFocus}
                            formik={formik}
                            name="software_delivered"
                            options={this.dropDownValuesMapper(this.state.allFieldValues.qualification_listing.software_delivered_listing)}
                            // onBlur={() => this.setState({ qualification: { ...this.state.qualification, dev: false } })}
                            // onFocus={() => this.setState({ qualification: { ...this.state.qualification, dev: true } })}
                            onFocus={() => this.handleFocus("dev")}
                            onBlur={() => this.handleBlur("dev")}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="mt-1">
                      <Grid item>
                        <TextField
                          name="product_idea"
                          fullWidth
                          variant="filled"
                          label={<TextFieldLabel label="What is their product idea" isRequired={true} error={this.getFormikError(formik.touched.product_idea, formik.errors.product_idea)} />}
                          // label="What is their product idea"
                          multiline
                          inputProps={{maxLength:2000}}
                          value={formik.values.product_idea}
                          onChange={formik.handleChange}
                          error={this.getFormikError(formik.touched.product_idea, formik.errors.product_idea)}
                          disabled={this.state.refferalStatus.disableAllField}
                        // helperText={this.getFormikHelperText(formik.touched.product_idea, formik.errors.product_idea)}
                        />
                      </Grid>
                    </Box>
                    <Box className="mt-1">
                      <Box>
                        <Grid className="grid-even-space">
                          <Grid item xs={12} md={12}>
                            <DropDown
                              data-test-id="dropdown"
                              label="App category"
                              // focused={this.state.qualification.appCategory}
                              focused={this.state.appCategoryFocus}
                              formik={formik}
                              isRequired={true}
                              name="app_category"
                              options={this.dropDownValuesMapper(this.state.allFieldValues.qualification_listing.categories_listing)}
                              // onBlur={() => this.setState({ qualification: { ...this.state.qualification, appCategory: false } })}
                              // onFocus={() => this.setState({ qualification: { ...this.state.qualification, appCategory: true } })}
                              onFocus={() => this.handleFocus("appCategory")}
                              onBlur={() => this.handleBlur("appCategory")}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <DropDown
                              data-test-id="dropdown"
                              label="Their stage of idea"
                              // focused={this.state.qualification.stageIdea}
                              focused={this.state.stageIdeaFocus}
                              formik={formik}
                              isRequired={true}
                              name="idea_stage"
                              options={this.dropDownValuesMapper(this.state.allFieldValues.qualification_listing.stages_of_idea_listing)}
                              // onBlur={() => this.setState({ qualification: { ...this.state.qualification, stageIdea: false } })}
                              // onFocus={() => this.setState({ qualification: { ...this.state.qualification, stageIdea: true } })}
                              onFocus={() => this.handleFocus("stageIdea")}
                              onBlur={() => this.handleBlur("stageIdea")}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* Additional note */}
            <Box className="deal-form-container">
              <Accordion data-test-id="Accordion" expanded={this.state.accordionStatus.additionalNotes}
                onChange={(_, isOpen) =>
                  this.updateAccordionStatus('additionalNotes', isOpen)
                } className="deal-form-accordition">
                <AccordionSummary
                  expandIcon={<div className="accordition-icon"><img src={accordition_icon} /></div>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="deal-form-heading">Additional notes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className="deal-form-internal-container">
                    <Box>
                      <Grid item>
                        <TextField
                          name="description"
                          fullWidth
                          variant="filled"
                          // label="Additional notes"
                          label={<TextFieldLabel label="Additional notes" isRequired={true} error={this.getFormikError(formik.touched.description, formik.errors.description)} />}
                          multiline
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          // required
                          error={this.getFormikError(formik.touched.description, formik.errors.description)}
                          disabled={this.state.refferalStatus.disableAllField}
                        // helperText={this.getFormikHelperText(formik.touched.describe, formik.errors.describe)}
                        />
                      </Grid>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Box>
      </Box>
    </form >)
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box sx={webStyles.container}>
        <style>
          {
            `
              .addPlusIcon:hover{
                background-color:#F3EBFF !important;
                cursor:pointer;
              }

              `
            // .MuiFormLabel-asterisk{
            //   color: red !important;
            // }
          }
        </style>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper" style={{height:"100vh",overflow:"hidden"}}>
            <Grid>
              <TopNavBar currentPage={{ name: 'Referrals', path: 'referrals' }} subPage={{ name: 'Register referral' }} />
              {
                this.state.isLoading || !this.state.countryData.length ?
                  // <Loader loading={this.state.isLoading} contentLoader={true} />
                  <RegisterReferralSkeleton />
                  :
                  <Box style={webStyles.mainContainer}>
                    <div className="page-title-block">
                      <div className="left-block">
                        <Typography className="deal-heading-main">
                          Register referral
                        </Typography>
                        <Typography className="deal-heading-sub">
                          Please fill out the required fields to register a new referral.
                        </Typography>
                      </div>
                    </div>
                    <div>
                      <Formik
                        enableReinitialize
                        initialValues={this.state.referralInitValue}
                        validationSchema={leadCompanyregistrationSchema(this.state.createNewCompany)}
                        onSubmit={(values, formik) => {
                          setTimeout(()=>{
                            localStorage.removeItem("leadFormData")
                            this.setState({
                              refferalStatus: {
                                isDraft: false,
                                isDelete: false,
                                isSave: true,
                                disableAllField: false
                              }
                            }, () => {
                              this.handleLeadSubmit(values, formik)
                            })
                          },800)
                        }}
                        innerRef={this.formikRef}

                      >
                        {(formik) => (
                          this.renderReferralForm(formik)
                        )}
                      </Formik>
                    </div>
                  </Box>
              }
            </Grid>
          </Grid>
        </Grid>
        {/* referral registered successfully modal  */}
        <Modal
          open={this.state.confirmationModal}
          onClose={this.closeConfirmationModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="confirmation-modal custom-modal modal-sm"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Box className="modal-body">
            <IconButton data-test-id="btnCloseConfirmationModal" disableRipple className="close-btn" onClick={() => this.closeConfirmationModal()}><img src={closeIcon} alt="close" /></IconButton>
            <Box className="modal-content">
              <Box className="image-block">
                <img src={registerReferral} alt="success" />
              </Box>
              <Box className="content-wrap">
                <Typography variant="h4">You referral has been added</Typography>
                <Typography>Details entered have been recorded, and sent to Builder.</Typography>
              </Box>
              <Box className="btn-wrap">
                <Button data-test-id="navigate-lead-dashboard" 
                onClick={() =>{ 
                  this.navigateToLeadDashboard()
                  }} className="custom-btn light-btn">View referral</Button>
                <Button data-test-id="close-confirmation-model" 
                onClick={() =>{ 
                  this.closeConfirmationModal()
                }}
                 className="custom-btn secondary-border-btn"
                  variant="outlined">Register new referral</Button>
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* discard modal  */}
        <Modal
          open={this.state.discardModal}
          onClose={this.closeDiscardModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="discard-modal custom-modal modal-xs"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Box className="modal-body">
            <IconButton
              data-test-id="btnCloseDiscardModal"
              disableRipple
              className="close-btn"
              onClick={() => this.closeDiscardModal()}>
              <img src={closeIcon} alt="close" />
            </IconButton>
            <Box className="modal-content">
              <Box className="content-wrap">
                <Typography variant="h4">Would you like to discard this draft?</Typography>
                <Typography>All the made changes will be deleted for this referral and you might have to start over</Typography>
              </Box>
              <Box className="btn-wrap">
                <Button
                  onClick={() => this.closeDiscardModal()}
                  className="custom-btn gray-btn">
                  Cancel
                </Button>
                <Button
                  data-test-id="draft"
                  onClick={() => {
                    this.resetForm()
                    this.discardToastOpen()
                  }}
                  className="custom-btn red-btn" variant="outlined">
                  Discard
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* Discard Snackbar  */}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.discardToastOpen}
          autoHideDuration={6000}
          onClose={this.discardToastClose}
          message="Your draft has been deleted"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                disableRipple
                className="close-btn"
                onClick={(event) => this.discardToastClose(event, 'manual')}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflowX: "hidden"
  },
  mainContainer: {
    padding: '16px 0'
  },
  rightAlign: {
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    width: "100%",
    borderTop: "1px solid #DFE0E6",
  },
};
const currencyStyles = {
  control: (styles: any) => ({
    ...styles,
    width: '66px',
    boxShadow: 'none',
    height: 56,
    borderRadius: '8px 0px 0px 8px',
    textAlign: 'center',
    border: '1px solid #C0C3CE',
  })
};
const countryStyles = {
  control: (styles: any) => ({
    ...styles,
    minWidth: '110px',
    boxShadow: 'none',
    height: 56,
    borderRadius: '8px 0px 0px 8px',
    textAlign: 'center',
    border: '1px solid #C0C3CE',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: '8px',
    border: '1px solid var(--true - grey - 3, #F5F5F5)',
    background: 'var(--0, #FFF)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    paddingBottom: 0,
    paddingTop: 0,
    width: '420px'

  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 8,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? '#F3EBFF' : 'white',
    color: state.isSelected ? '#3C3E49' : '#3C3E50',
    '&:hover': {
      background: '#F3EBFF',
    },
    fontSize: '14px',
    lineHeight: '24px',
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    background: '#F3EBFF',
    display: 'flex',
    alignItems: 'center',

  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginRight: '0px',
    marginTop: '12px', // Adjust margin as needed q
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#3C3E49",
    padding: '16px'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '2px 14px',
  }),
  input: (provided: any) => ({
    ...provided,
    marginTop: '15px',
    paddingTop: '0px',
    caretColor: 'transparent'
  })
}

const selectStyle = {
  control: (styles:any) => ({
    ...styles,
    height: 56,
    border: '1px solid #C0C3CE',
    boxShadow: 'none',
    borderRadius: 8,
    '&:hover': {
      // border: '1px solid #6200EA',
      boxShadow: 'none',
    },
    '&::placeholder': {
      marginTop: '10px',
      color: '#83889E',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      fontFamily: "'Rubik', sans-serif",
    },
  }),
  menu: (provided:any) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: '8px',
    border: '1px solid var(--true - grey - 3, #F5F5F5)',
    background: 'var(--0, #FFF)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    paddingBottom: 0,
    paddingTop: 0
  }),
  menuList: (provided:any) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
    borderRadius: 8,
  }),
  option: (provided:any, state:any) => ({
    ...provided,
    background: state.isSelected ? '#F3EBFF' : 'white',
    color: state.isSelected ? '#3C3E49' : '#3C3E50',
    '&:hover': {
      background: '#F3EBFF',
    },
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
  }),
  noOptionsMessage: (provided:any) => ({
    ...provided,
    background: '#F3EBFF',
    display: 'flex',
    alignItems: 'center',

  }),
  singleValue: (provided:any) => ({
    ...provided,
    marginRight: '0px',
    marginTop: '12px', // Adjust margin as needed q
  }),
  dropdownIndicator: (provided:any) => ({
    ...provided,
    color: "#3C3E49",
    padding: '16px'
  }),
  valueContainer: (provided:any) => ({
    ...provided,
    padding: '2px 14px',
  }),
  input: (provided:any) => ({
    ...provided,
    marginTop: '15px',
    paddingTop: '0px',
    caretColor: 'transparent'
  })
}


// Customizable Area End
